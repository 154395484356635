body {
  color: white;
  display: flex;
  justify-content: center;
  flex-direction: column; /* Stack children vertically */
  height: 100vh; /* Set height to fill the viewport */
  font-family: monospace; /* Apply monospaced font to all text */
  background: linear-gradient(45deg, #660000, #330000 25%, #800000 50%, #330000 75%, #660000);
  animation: gradientChange 10s ease infinite; /* Apply animation to the background gradient */
  background-size: 400% 400%; /* Increase the size of the background gradient */
}

.App-header {
  margin-bottom: 20px; /* Add some space below the header */
}

.radio-image {
  position: fixed;
  top: calc(33vh); /* Adjust vertical position */
  left: 50%;
  transform: translateX(-50%);
  width: 100px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
}

.now-container {
  font-size: 30px;
}

.message-1, .message-2 {
  display: flex;
  justify-content: center; /* Center align the content */
}

.message-1 {
  position: absolute;
  justify-content: center; /* Center align the content */
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
}

.message-2 {
  font-size: 20px;
  text-align: center;
  justify-content: center; /* Center align the content */
  animation: vibrate 0.1s infinite alternate; /* Animation for slight vibrating */
}

.next-container {
  font-size: 10px;
}

/* Apply Grid layout to the containers */
.now-container, .next-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Create three equal columns */
  width: 100%; /* Take full width of the page */
}

.now-container > div, .next-container > div {
  /* border: 1px solid #ffffff; /* Example: white border, adjust the color as needed */
  padding: 10px; /* Optional: Adds some spacing inside the divs */
}

/* Adjust the spacing for start and end elements */
.now-starts, .next-starts {
  padding-right: 20px; /* Adds space to the right */
  justify-self: right; /* Align to the end (right) of the grid cell */
}

.now-ends, .next-ends {
  padding-left: 20px; /* Adds space to the left */
  justify-self: left;
}

.now-field, .next-field {
  white-space: nowrap;
  overflow: hidden;
}

.now-name, .next-name {
  display: inline-block;
  padding-left: 100%;
}

.now-name {
  animation: ScrollToLeft 10s linear infinite;
}

.next-name {
  animation: ScrollToRight 10s linear infinite;
}

@keyframes ScrollToLeft {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-120%, 0);
  }
}

@keyframes ScrollToRight {
  0% {
    transform: translate(-120%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
/*
@keyframes vibrate {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(1px);
  }
}
*/

@keyframes gradientChange {
  0% {
    background-position: 0% 50%; /* Start position of the gradient */
  }
  50% {
    background-position: 100% 50%; /* Middle position of the gradient */
  }
  100% {
    background-position: 0% 50%; /* End position of the gradient */
  }
}

.clock {
  justify-content: center;
  display: flex;
  font-size: 16px;
}